<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span>{{ l('编辑月汇总') }}</span>
				</div>
			</div>
			<div class="my-md" style="height: 600px;overflow: auto">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('出勤天数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number v-model="entity.attDays" :min="0" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('休息天数')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input-number v-model="entity.restDays" :min="0" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('应出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.standardLobor" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('实际出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.factLabor" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('工作日缺勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.absenceDays" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('法定缺勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.holidayAbsence" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('夜班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.nightNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('迟到')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.lateNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('早退')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.earlyNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('旷工')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.absentNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('迟到')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.lateMinutes" :min="0"/></span>
						<span class="suffix"> 分钟</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('早退')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.earlyMinutes" :min="0"/></span>
						<span class="suffix"> 分钟</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('旷工')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.absentHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('周末应出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekStandardLobor" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('周末实际出勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekFactLabor" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('周末缺勤')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekAbsence" :min="0"/></span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('周末事假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekSjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('周末病假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekBjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('周末有薪假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.weekendPaidLeave" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('加班累计')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.ot" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('工作日加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT1" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('周末加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT2" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('法定加班')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.oT3" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('哺乳假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.brHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('丧假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.sjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('婚假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.hjHours" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('产假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.cjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('年假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.njHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('公出假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.gcHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('工伤假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.gsHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('陪产假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.pcHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('事假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.sjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('病假')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.bjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('调休')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.txHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('产检假/时')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.cjjHours" :min="0"/></span>
						<span class="suffix"> 小时</span>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('因私签卡')"> </q-label>
					</a-col>
					<a-col :span="8">
						<span><a-input-number v-model="entity.signCardNums" :min="0"/></span>
						<span class="suffix"> 次</span>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('状态')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-select style="width: 90%" v-model="entity.state" placeholder="状态" allowClear>
							<a-select-option v-for="item in dailySummaryStateList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('审核人')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div class="auditUser" @click="auditUser">
							<a-tag
								color="#2db7f5"
								v-for="item in auditUserList"
								:key="item.id"
								style="cursor: pointer;"
							>
								{{ item.realName }}
							</a-tag>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('审核时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							v-model="entity.auditTime"
							style="width: 90%"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('员工确认时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							v-model="entity.selfCheckTime"
							style="width: 90%"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('结账人')"> </q-label>
					</a-col>
					<a-col :span="8">
						<div class="auditUser" @click="passUser">
							<a-tag color="#2db7f5" v-for="item in passUserList" :key="item.id" style="cursor: pointer;">
								{{ item.realName }}
							</a-tag>
						</div>
					</a-col>
					<a-col :span="4">
						<q-label :label="l('结账时间')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-date-picker
							v-model="entity.passTime"
							style="width: 90%"
							format="YYYY-MM-DD HH:mm"
							:show-time="{ format: 'HH:mm' }"
						/>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { Dic } from '../../../../shared/utils';
import AbpUserSelect from '../../../../components/abp-user-select/abp-user-select';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import { EmpAttMonthlyEditDto, EmpattmonthlyServiceProxy } from '../../../../shared/service-proxies';

export default {
	name: 'edit-month-summary',
	mixins: [ModalComponentBase],
	components: { AbpUserSelect },
	data() {
		return {
			zh_CN,
			dailySummaryStateList: [],
			auditUserList: [],
			passUserList: [],
			entity: new EmpAttMonthlyEditDto(),
			id: undefined,
		};
	},
	created() {
		this.fullData();
		this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.dailySummaryStateList = await Dic.getInstance().getDicAsync('QYB_DailySummaryState');
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._empattmonthlyServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res;
					if (this.entity.auditUserId) {
						this.auditUserList = [
							{
								id: this.entity.auditUserId,
								realName: this.entity.auditUserRealName,
							},
						];
					}
					if (this.entity.passUserId) {
						this.passUserList = [
							{
								id: this.entity.passUserId,
								realName: this.entity.passUserRealName,
							},
						];
					}
				});
		},
		/**
		 * 结账人选择
		 */
		passUser() {
			ModalHelper.create(
				AbpUserSelect,
				{
					_selectedRowKeys: _.map(this.passUserList, 'id'),
					_selectedRows: this.passUserList,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res.length > 0) {
					this.passUserList = res;
				} else {
					this.passUserList = [];
				}
			});
		},
		/**
		 * 审核人选择
		 */
		auditUser() {
			ModalHelper.create(
				AbpUserSelect,
				{
					_selectedRowKeys: _.map(this.auditUserList, 'id'),
					_selectedRows: this.auditUserList,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res.length > 0) {
					this.auditUserList = res;
				} else {
					this.auditUserList = [];
				}
			});
		},
		save() {
			if (this.auditUserList.length > 0) {
				this.entity.auditUserId = this.auditUserList[0].id;
			} else {
				this.entity.auditUserId = undefined;
			}
			if (this.passUserList.length > 0) {
				this.entity.passUserId = this.passUserList[0].id;
			} else {
				this.entity.passUserId = undefined;
			}
			this.loading = true;
			this._empattmonthlyServiceProxy
				.update(this.entity)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.suffix {
	padding-left: 5px;
}

.auditUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	width: 90%;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
.auditUser::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}
.auditUser::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.auditUser::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
