<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="3">
					<a-month-picker
						style="width: 100%"
						v-model="initialValue"
						:allowClear="false"
						@change="onChangeMon"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						allowClear
						multiple
						@change="handleCompanyChange"
						:placeholder="l('Company')"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						v-model="departmentId"
						@change="search"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-select
						mode="tags"
						v-model="empType"
						@change="search"
						style="width: 100%"
						placeholder="员工类型"
						allowClear
					>
						<a-select-option v-for="item in empTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select
						mode="tags"
						show-search
						showArrow
						allowClear
						aria-required="true"
						option-filter-prop="children"
						v-model="attGroupId"
						style="width: 100%"
						@change="search"
						placeholder="考勤组"
					>
						<a-select-option v-for="item in attendanceGroup" :key="item.id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select v-model="state" @change="search" style="width: 100%;" placeholder="状态" allowClear>
						<a-select-option v-for="item in dailySummaryStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4" style="text-align: right">
					<a-input-search
						v-model.trim="filterText"
						placeholder="请输入搜索内容..."
						style="width: 100%;max-width: 200px"
						enter-button
						@search="search"
					/>
				</a-col>
			</a-row>
			<!--                    出勤周期提示-->
			<a-row>
				<p style="font-size: 10px;color:#BEBEBE ">考勤周期:{{ startTime }}~{{ endTime }}</p>
			</a-row>
			<a-row :gutter="[8, 8]">
				<a-col :span="24">
					<a-button type="primary" @click="analysis" v-if="isGranted('month_summary_analysis')">
						分析
					</a-button>
					<a-button type="primary" @click="batchCheck" v-if="isGranted('month_summary_batchcheck')">
						批量审核
					</a-button>
					<a-button
						type="primary"
						@click="batchChangeStatus"
						v-if="isGranted('month_summary_batchChangeStatus')"
					>
						<a-icon type="edit" />
						批量修改状态
					</a-button>
					<a-button type="primary" @click="exportFormClick" v-if="isGranted('month_summary_excel')">
						<a-icon type="download" />
						<span>导出</span>
					</a-button>
					<a-button type="primary" @click="editMonthSummary" v-if="isGranted('month_summary_edit')">
						<a-icon type="edit" />
						<span>编辑</span>
					</a-button>
					<a-button type="primary" @click="sendNotice" v-if="isGranted('month_summary_sendNotice')">
						<span>消息推送</span>
					</a-button>
					<a-button type="primary" @click="syncSalaryItem" v-if="isGranted('month_summary_sync_salaryitem')">
						<span>考勤过数</span>
					</a-button>
					<a-button type="primary" @click="showAddress">
						<span>考勤定位点</span>
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="dutyFrom" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="auditTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="selfCheckTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
				<span slot="passTime" slot-scope="text">{{ text ? text.format('YYYY-MM-DD HH:mm') : '' }}</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpattdailyServiceProxy,
	EmpattmonthlyServiceProxy,
	EnterpriseAttendCycleServiceProxy,
	UpdateStateDto,
	EmpattmonthlyAnalysisDtos,
	SyncSalaryItemsInput,
} from '../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../shared/utils';
import { AppComponentBase } from '../../../shared/component-base';
import { ModalHelper } from '../../../shared/helpers/modal';
import moment from 'moment';
import BatchEdit from '../daily-summary/component/batch-edit';
import * as _ from 'lodash';
import analysis from './analysis/analysis';
import { abpService } from '../../../shared/abp';
import EditMonthSummary from './edit-month-summary/edit-month-summary';
import SyncSalaryItems from './sync-salary-items/sync-salary-items';
import ShowAttAddress from './show-att-address/show-att-address';

import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'month-summary',
	mixins: [AppComponentBase],
	components: {
		analysis,
		EditMonthSummary,
		SyncSalaryItems,
		ShowAttAddress,
	},
	data() {
		return {
			attendanceGroup: [], //考勤组
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			abnormalTypeList: [], //异常类型
			dailySummaryStateList: [], //状态
			startTime: undefined, //开始时间
			endTime: undefined, //结束时间
			attGroupId: undefined, //考勤组
			state: undefined, //状态

			DailySummaryState: '', //状态(1:待审核、2"已审核、3:员工已确认、4:已结账)--数据字典
			columns: [
				{
					title: '工号',
					width: 100,
					sorter: true,
					ellipsis: true,
					dataIndex: 'jobNumber',
					fixed: 'left',
					align: 'center',
				},
				{
					title: '姓名',
					width: 100,
					sorter: true,
					ellipsis: true,
					dataIndex: 'realName',
					fixed: 'left',
					align: 'center',
				},
				{ title: '公司', width: 100, sorter: true, ellipsis: true, dataIndex: 'company', align: 'center' },
				{
					title: '一级部门',
					width: 100,
					sorter: true,
					ellipsis: true,
					dataIndex: 'department',
					align: 'center',
				},
				{
					title: '二级部门',
					dataIndex: 'twoDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{
					title: '三级部门',
					dataIndex: 'threeDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{ title: '入职日期', ellipsis: true, sorter: true, dataIndex: 'hireDate', align: 'center' },
				{ title: '离职日期', ellipsis: true, sorter: false, dataIndex: 'termDate', align: 'center' },
				{ title: '员工类型', ellipsis: true, sorter: true, dataIndex: 'empType', align: 'center' },
				{ title: '出勤天数', ellipsis: true, sorter: true, dataIndex: 'attDays', align: 'center' },
				{ title: '休息天数', ellipsis: true, sorter: true, dataIndex: 'restDays', align: 'center' },
				{ title: '工作日应出勤/时', ellipsis: true, sorter: true, dataIndex: 'standardLobor', align: 'center' },
				{ title: '实际出勤/时', ellipsis: true, sorter: true, dataIndex: 'factLabor', align: 'center' },
				{ title: '工作日缺勤/时', sorter: true, dataIndex: 'absenceDays', ellipsis: true, align: 'center' },
				{ title: '周末缺勤/时', sorter: true, dataIndex: 'weekAbsence', ellipsis: true, align: 'center' },
				{ title: '法定缺勤/时', sorter: true, dataIndex: 'holidayAbsence', ellipsis: true, align: 'center' },
				{ title: '公出/时', dataIndex: 'gcHours', sorter: true, align: 'center' },
				{ title: '周末应出勤/时', sorter: true, dataIndex: 'weekStandardLobor', width: 150, align: 'center' },
				{ title: '周末实际出勤/时', sorter: true, dataIndex: 'weekFactLabor', width: 150, align: 'center' },
				{ title: '周末有薪假/时', width: 150, sorter: true, dataIndex: 'weekendPaidLeave', align: 'center' },
				{ title: '周末事假/时', width: 150, sorter: true, dataIndex: 'weekSjHours', align: 'center' },
				{ title: '周末病假/时', width: 150, sorter: true, dataIndex: 'weekBjHours', align: 'center' },
				{ title: '工作日加班/时', width: 150, sorter: true, dataIndex: 'oT1', align: 'center' },
				{ title: '周末加班/时', dataIndex: 'oT2', sorter: true, align: 'center' },
				{ title: '法定加班/时', dataIndex: 'oT3', sorter: true, align: 'center' },
				{ title: '加班合计/时', dataIndex: 'ot', sorter: true, align: 'center' },
				{ title: '调休/时', dataIndex: 'txHours', sorter: true, align: 'center' },
				{ title: '年假/时', dataIndex: 'njHours', sorter: true, align: 'center' },
				{ title: '婚假/次', dataIndex: 'hjHours', sorter: true, align: 'center' },
				{ title: '产假/时', dataIndex: 'cjHours', sorter: true, align: 'center' },
				{ title: '陪产假/时', dataIndex: 'pcHours', sorter: true, align: 'center' },
				{ title: '工伤假/时', dataIndex: 'gsHours', sorter: true, align: 'center' },
				{ title: '哺乳假/时', dataIndex: 'brHours', sorter: true, align: 'center' },
				{ title: '丧假/时', dataIndex: 'ssHours', sorter: true, align: 'center' },
				{ title: '产检假/时', sorter: true, dataIndex: 'cjjHours', align: 'center' },
				{ title: '有薪假合计/时', dataIndex: 'paidLeaveHours', sorter: true, align: 'center' },
				{ title: '事假/时', dataIndex: 'sjHours', sorter: true, align: 'center' },
				{ title: '病假/时', dataIndex: 'bjHours', sorter: true, align: 'center' },
				{ title: '夜班/次', ellipsis: true, sorter: true, dataIndex: 'nightNums', align: 'center' },
				{ title: '迟到/次', ellipsis: true, sorter: true, dataIndex: 'lateNums', align: 'center' },
				{ title: '早退/次', ellipsis: true, sorter: true, dataIndex: 'earlyNums', align: 'center' },
				{ title: '旷工/次', sorter: true, dataIndex: 'absentNums', align: 'center' },
				{ title: '迟到/分钟', sorter: true, dataIndex: 'lateMinutes', align: 'center' },
				{ title: '早退/分钟', sorter: true, dataIndex: 'earlyMinutes', align: 'center' },
				{ title: '旷工/时', sorter: true, dataIndex: 'absentHours', align: 'center' },
				{ title: '因私签卡/次', dataIndex: 'signCardNums', sorter: true, align: 'center' },
				// {title: '异常类型', dataIndex: 'abnormalType', align: 'center',},
				{ title: '状态', ellipsis: true, dataIndex: 'state', sorter: true, align: 'center' },
				{ title: '审核人', ellipsis: true, dataIndex: 'auditUserName', sorter: true, align: 'center' },
				{
					title: '审核时间',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'auditTime',
					align: 'center',
					scopedSlots: { customRender: 'auditTime' },
				},
				{
					title: '员工自助确认状态',
					width: 200,
					ellipsis: true,
					sorter: true,
					dataIndex: 'selfCheckState',
					align: 'center',
					scopedSlots: { customRender: 'selfCheckState' },
				},
				{
					title: '员工确认时间',
					width: 200,
					sorter: true,
					ellipsis: true,
					dataIndex: 'selfCheckTime',
					align: 'center',
					scopedSlots: { customRender: 'selfCheckTime' },
				},
				{ title: '过数人', ellipsis: true, sorter: true, dataIndex: 'passUserName', align: 'center' },
				{
					title: '过数时间',
					width: 200,
					ellipsis: true,
					dataIndex: 'passTime',
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'passTime' },
				},
			],
			tableData: [],

			attenanceDateApi: undefined,
			initialValue: undefined, //出勤日期初始值
			dictionaryListApi: undefined,
			_CompanyConfigServiceProxy: undefined, //公司接口
			_DepartmentServiceProxy: undefined, //部门接口
			groupListApi: undefined, //考勤组
			_fileDownloadService: undefined, //导出
			ids: [], //批量的ids
			empTypeList: [],
			empType: undefined,

			//表格是否多选
			isMultiple: true,
		};
	},
	created() {
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		//考勤组
		this.groupListApi = new EmpattdailyServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
		// 考勤周期
		this.attenanceDateApi = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this.columns = this.getColumn('考勤月汇总', this.columns, 150);
	},
	async mounted() {
		this.initCompany();
		this.attendanceGroup = await this.initAttGroup();
		this.dailySummaryStateList = await Dic.getInstance().getDicAsync('QYB_DailySummaryState');
		this.empTypeList = await Dic.getInstance().getDicAsync('EmpType');
		// 初始化出勤日期
		const res = await this.attenanceDateApi.getCycleByNow();
		this.startTime = res.startTime;
		this.endTime = res.endTime;
		this.initialValue = moment(new Date(res.yearOrMonth));
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
		}
		this.getData();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		//选择公司
		handleCompanyChange(value) {
			console.log('value', value);
			this.comPanyId = value;
			this.search();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		//更新
		getData() {
			this.loading = true;
			this._empattmonthlyServiceProxy
				.getPgeds(
					this.initialValue.year(),
					this.initialValue.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.state,
					this.empType,
					this.filterText ? this.filterText : undefined,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this.loading = false;
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		//选择月份
		onChangeMon(date, dateString) {
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.attenanceDateApi
				.getByTenant(this.initialValue.year(), this.initialValue.month() + 1)
				.finally(() => {
					// this.spinning = false
				})
				.then((res) => {
					this.startTime = res.startTime;
					this.endTime = res.endTime;
					this.search();
				});
		},
		//分析
		analysis() {
			this.$confirm({
				title: '分析确认',
				content: '您确认要进行月汇总数据分析吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					const input = new EmpattmonthlyAnalysisDtos();
					input.companyId = this.comPanyId?.toString();
					input.departmentId = this.departmentId?.toString();
					input.userIds = this.selectedRows.map((r) => r.empId)?.toString();
					input.attGroupId = this.attGroupId?.toString();
					input.year = this.initialValue.year();
					input.month = this.initialValue.month() + 1;
					console.log('input', input);
					this.loading = true;
					this._empattmonthlyServiceProxy
						.analysis(input)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: '分析成功',
							});
							this.search();
						});
				},
			});
		},
		//批量审核
		batchCheck() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('至少选择一项进行操作');
			}
			let rows = this.selectedRows.filter((item) => item.state == '待审核');
			let ids = _.map(rows, 'id');
			this.$confirm({
				title: '审核确认',
				content: '确认要执行此操作吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					let entity = new UpdateStateDto();
					entity.ids = ids;
					entity.stateCode = '2';
					this._empattmonthlyServiceProxy.updateState(entity).then((res) => {
						this.search();
					});
				},
			});
		},
		//批量修改状态
		batchChangeStatus() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			ModalHelper.create(
				BatchEdit,
				{
					ids: this.selectedRowKeys,
					isMonth: true,
				},
				{
					width: '30%',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
		//导出
		exportFormClick() {
			let todayDate = new Date();
			this.loading = true;
			this._empattmonthlyServiceProxy
				.getToExcelFile(
					this.initialValue.year(),
					this.initialValue.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.attGroupId,
					this.state,
					this.empType,
					this.filterText ? this.filterText : undefined,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		//查看考勤地点
		showAddress() {
			if (this.selectedRowKeys.length > 1) {
				abp.message.warn('请选择一项进行操作');
				return;
			}
			if (this.selectedRowKeys.length < 1) {
				abp.message.warn('请选择一项进行操作');
				return;
			}
			ModalHelper.create(
				ShowAttAddress,
				{
					empId: this.selectedRows[0].empId,
					startTime: this.startTime,
					endTime: this.endTime,
				},
				{
					width: '80%',
				}
			).subscribe((res) => {
				// this.selectedRowKeys = [];
				// this.selectRows = [];
				// this.getData();
			});
		},

		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		//初始化考勤组
		async initAttGroup() {
			return new Promise((resolve) => {
				this.groupListApi.getGroupListByManage().then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},

		/**
		 * 编辑
		 */
		editMonthSummary() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRows.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			ModalHelper.create(
				EditMonthSummary,
				{
					id: this.selectedRows[0].id,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
		sendNotice() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.$confirm({
				title: '确认操作',
				content: '您确定要给已审核汇总人员推送确认消息吗?',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._empattmonthlyServiceProxy
						.sendExamineNotice(
							this.initialValue.year(),
							this.initialValue.month() + 1,
							this.comPanyId,
							this.departmentId,
							this.attGroupId,
							this.state,
							this.filterText ? this.filterText : undefined,
							undefined,
							this.request.maxResultCount,
							this.request.skipCount
						)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('发送成功'),
							});
						});
				},
			});
		},
		/**
		 * 过数
		 */
		syncSalaryItem() {
			this.$confirm({
				title: '考勤过数确认',
				content: '启用员工自助确认时，员工自助确认状态必须是“员工已确认”或“超时”，否则只需要月汇总状态为“已审核”或“已过数”',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					const input = new SyncSalaryItemsInput();
					input.startTime = moment(this.startTime);
					input.endTime = moment(this.endTime);
					input.year = this.initialValue.year();
					input.month = this.initialValue.month() + 1;
					input.attGroupId = this.attGroupId?.toString();
					input.companyId = this.comPanyId?.toString();
					input.departmentId = this.departmentId?.toString();
					input.empIds = this.selectedRows.map((r) => r.empId);

					this.loading = true;
					this._empattmonthlyServiceProxy
						.syncSalaryItems(input)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: '过数成功',
							});
							this.search();
						});
				},
			});

			// let UserList = this.selectedRows.map((item) => {
			// 	return {
			// 		id: item.empId,
			// 		realName: item.realName,
			// 	};
			// });
			// ModalHelper.create(
			// 	SyncSalaryItems,
			// 	{
			// 		attDate: this.initialValue,
			// 		UserList: UserList,
			// 	},
			// 	{ width: '600px' }
			// ).subscribe((res) => {});
		},
	},
};
</script>

<style scoped>
.margin-left-10 {
	margin-left: 10px;
}
</style>
