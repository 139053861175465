<template>
    <a-spin :spinning="loading">
        <!-- 标题 -->
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="search" />
                <span>查看考勤定位点</span>
            </div>
        </div>
        <div :style="{ width: '100%', height: '600px' }">
            <el-amap vid="amapDemo" :zoom="zoom" style="height: 100%" :center="center" :plugin="plugin">
                <el-amap-marker
                    v-for="(marker, key) in checkInRecordList"
                    :key="key + '1'"
                    :position="marker.position"
                    :events="marker.events"
                    :title="marker.title"
                    :vid="key"
                ></el-amap-marker>
                <el-amap-circle
                    v-for="(circle, key) in checkInAddressList"
                    :center="circle.center"
                    :radius="circle.radius"
                    :strokeWeight="1"
                    :fillOpacity="fillColorOpacity"
                    :strokeColor="fillColor"
                    :fillColor="fillColor"
                    :events="circle.events"
                    :key="key"
                ></el-amap-circle>
            </el-amap>
        </div>
    </a-spin>
</template>
<script>
import { ModalComponentBase } from '@/shared/component-base';
import { EmpattmonthlyServiceProxy } from '@/shared/service-proxies';
import moment from 'moment';

export default {
    mixins: [ModalComponentBase],
    data() {
        return {
            empId: undefined,
            startTime: undefined,
            endTime: undefined,

            _empattmonthlyServiceProxy: undefined,

            //打卡记录
            checkInRecordList: [],
            //考勤组位置
            checkInAddressList: [],
            //圆圈填充颜色和线条颜色
            fillColor: '#2979ff',
            fillColorOpacity: 0.5,

            //地图缩放等级  数值越小，看的东西越多
            zoom: 16,
            //地图默认位置：江苏省苏州市吴中区木渎镇骏创塑胶模具
            center: [120.532687, 31.241221],
            //地图使用的插件
            plugin: [
                'ToolBar',
                {
                    pName: 'MapType',
                    defaultType: 0,
                },
            ],

            /*****  地图文档https://www.wenjiangs.com/doc/fsjxllsq */
        };
    },
    async mounted() {
        this.fullData();
        this._empattmonthlyServiceProxy = new EmpattmonthlyServiceProxy(this.$apiUrl, this.$api);
        this._empattmonthlyServiceProxy
            .getMonthPoint(this.empId, moment(this.startTime), moment(this.endTime))
            .then(({ checkInRecordList, checkInAddressList }) => {
                //获取所在考勤组的所有位置信息
                this.checkInAddressList = checkInAddressList.map((address) => ({
                    //圆圈中心点地理位置
                    center: [address.lng, address.lat],
                    //圆圈的半径
                    radius: address.validLimit,
                    events: {
                        click: () => {},
                    },
                }));
                //获取该员工所有的APP打卡记录
                this.checkInRecordList = checkInRecordList.map((check) => ({
                    position: [check.lng, check.lat], //标签地理位置
                    title: `${check.checkTime ? moment(check.checkTime).format('YYYY-MM-DD HH:mm:ss') : ''} 在[${
                        check.checkAddress
                    }]打卡`, //标题
                    events: {
                        //点击标签
                        click: (e) => {
                            if (check.checkTime) {
                                abp.message.info(
                                    `${moment(check.checkTime).format('YYYY-MM-DD HH:mm:ss')} 在[${
                                        check.checkAddress
                                    }]打卡`
                                );
                            } else {
                                abp.message.info(`在[${check.checkAddress}]打卡`);
                            }
                        },
                    },
                }));
                //设置当前地图位置为第一个打卡地理位置
                if (this.checkInRecordList.length > 0) {
                    this.center = this.checkInRecordList[0].position;
                }
            });
    },
};
</script>

<style scoped></style>
